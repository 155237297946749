<template>
  <!-- 区域规则 -->
  <div class="box-card" style="text-align:left;margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" ref="queryFormRef"
      @submit.native.prevent>
      <el-form-item label="区域规则：">
        <el-input maxlength="30" v-model="queryForm.rule_name" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.rule_name = validSpace(e))" placeholder="请输入区域规则"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="区域规则">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('e041ac7f53df47509cc73b1c')">删除
          </lbButton>
          <lbButton icon="xinzeng" @click="handelAreaRule('addnew')"
            v-if='this.getUserInfo.user_name != "admin" && isShowBtn("83683eddfed84de29ae20e68")'>新增</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
         <!-- 使用插件生产表格 -->
         <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
              <template slot-scope="{row}" slot="create_time">
                {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="handelAreaRule(row._id)"
                v-if="isShowBtn('cf78737501a2458c8bc75913')"></lbButton>
            </template>
          </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
          <el-table-column prop="organize_name" label="组织" min-width="150"></el-table-column>
          <el-table-column prop="rule_name" label="区域规则" min-width="150"></el-table-column>
          <el-table-column prop="group_count" label="考勤组">
            <template slot-scope="scope">
              <el-link type="primary" target="_blank" v-if="scope.row.group_count > 0" @click="openGroup(scope.row)">{{
                scope.row.group_count }}</el-link>
              <span v-else>{{ scope.row.group_count }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="handelAreaRule(scope.row._id)"
                v-if="isShowBtn('cf78737501a2458c8bc75913')"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
    <!-- 新增编辑区域规则 -->
    <lebo-dialog :title="dialogAreaRuleId==='addnew'?'新增区域规则':'编辑区域规则'" :isShow="showAddAreaRule" width="70%" @close="showAddAreaRule = false" footerSlot>
      <addAreaRule v-if="showAddAreaRule" :id="dialogAreaRuleId" @closeDialog="closeAreaRule"></addAreaRule>
    </lebo-dialog>
     <!-- 考勤组详情对话框 -->
     <lebo-dialog title="考勤组" :isShow="isShowGroupDetail" width="30%" @close="isShowGroupDetail = false" footerSlot
      closeOnClickModal>
      <el-table :data="showGroupDetail" tooltip-effect="dark" style="width: 100%" border stripe height="460">
        <el-table-column type="index" width="150" align="center" header-align="center" prop="_id"
          label="序号"></el-table-column>
        <el-table-column prop="attendance_group_name" label="考勤组"></el-table-column>
      </el-table>
    </lebo-dialog>
  </div>
</template>

<script>
import { getRegionalRulesList, delRegionalRules } from '@/api/administrative'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import addAreaRule from './components/addAreaRule/index.vue'
export default {
  components: { addAreaRule },
  data () {
    return {
      queryForm: {
        rule_name: '',
        PageIndex: 1,
        PageSize: 10
      },
      tableData: [],
      total: 0,
      multipleSelection: [],
      dialogAreaRuleId: '',
      showAddAreaRule: false,
      isShowGroupDetail: false,
      showGroupDetail: [], // 考勤组列表详情
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '组织',
            prop: 'organize_name'
          }, {
            label: '区域规则',
            prop: 'rule_name'
          }, {
            label: '考勤组',
            prop: 'group_count',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
      console.log(this.queryForm)
    }
    this.fnGetRegionalRulesList()
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getUserInfo'])
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    // this.requestPorkingList();
    // this.requestFindList();
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取考勤规则列表
    async fnGetRegionalRulesList () {
      const res = await getRegionalRulesList(this.queryForm)
      console.log(res)
      if (res && res.Code === 200) {
        if (res.Data && res.Data.DataList.length > 0) {
          this.tableData = res.Data.DataList
          this.total = res.Data.TotalCount
        } else {
          this.tableData = []
          this.total = 0
        }
      } else {
        this.tableData = []
        this.total = 0
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetRegionalRulesList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      //  // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetRegionalRulesList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetRegionalRulesList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetRegionalRulesList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      //  // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetRegionalRulesList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的区域规则, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              obj._ids = arr
              this.fndelAttendanceRules(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的区域规则！')
      }
    },
    // 删除区域规则接口
    async fndelAttendanceRules (obj) {
      const res = await delRegionalRules(obj)
      console.log(res)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetRegionalRulesList()
    },
    // 新增编辑区域规则
    handelAreaRule (id) {
      this.dialogAreaRuleId = id
      this.showAddAreaRule = true
    },
    closeAreaRule () {
      this.showAddAreaRule = false
      this.fnGetRegionalRulesList()
    },
    // 查看详细考勤组数据
    openGroup (data) {
      //  data.group_list.
      this.showGroupDetail = JSON.parse(data.group_list)
      this.isShowGroupDetail = true
    }
  }

}
</script>

<style scoped lang="less"></style>
