<template>
  <!-- 新增区域规则 -->
  <div class="box-card" style="text-align: left;">
    <!-- 主要内容 -->
    <div style="margin-top:15px;">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="150px" class="demo-ruleForm">
        <el-form-item label="规则名称：" prop="rule_name">
          <el-input v-model="addForm.rule_name" maxlength="30" placeholder="请输入规则名称"
            @input="(e) => (addForm.rule_name = validSpace(e))" style="width: 500px;"></el-input>
        </el-form-item>
        <el-form-item label="组织：" prop="organize_id">
          <el-select popper-class="my-select" filterable v-model="addForm.organize_id" placeholder="请选择组织" clearable @change="selectOrganize()" style="width: 500px;">
              <el-option v-for="item in organizationSeleteList" :key="item._id" :label="item.organize_name" :value="item._id"></el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="打卡区域：" prop="punch_in_area">
          <div class="map-container" id="map">
            <div class="searchMap">
              <el-form :inline="true" size="small">
                <el-form-item>
                  <el-select popper-class="my-select" v-model="addressInfo.province_code" filterable clearable
                    placeholder="请选择省" @change="provinceChange">
                    <el-option v-for="item in provinceSelectList" :key="item.specific_code" :label="item.specific_name"
                      :value="item.specific_code"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select popper-class="my-select" v-model="addressInfo.city_code" filterable clearable
                    placeholder="请选择市" @change="cityChange">
                    <el-option v-for="item in citySelectList" :key="item.specific_code" :label="item.specific_name"
                      :value="item.specific_code"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select popper-class="my-select" v-model="addressInfo.district_code" filterable clearable
                    placeholder="请选择区" @change="districtChange">
                    <el-option v-for="item in districtSelectList" :key="item.specific_code" :label="item.specific_name"
                      :value="item.specific_code"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-input id="inputkeyword" v-model="keyword" placeholder="请输入关键字"
                    @input="(e) => (keyword = validSpace(e))"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <!-- <div class="float-select">
              <input id="inputkeyword" placeholder="请输入地名" autocomplete="off" />
              <el-button type="primary" size="small" @click="searchMap">搜 索</el-button>
            </div> -->
            <div class="float-button">
              <el-button type="warning" size="small" plain v-if="addForm.punch_in_area.length > 0"  @click="startEditorPoly()">修 改</el-button>
              <el-button type="danger" size="small" plain v-if="addForm.punch_in_area.length > 0" @click="resetDrawe()">重 置</el-button>
              <el-button type="primary" size="small" plain v-if="adjustment" @click="saveEditorPoly()">保 存</el-button>
              <!-- <div v-if="addForm.punch_in_area.length > 0" class="btn" @click="resetDrawe()">
                <span>重置</span>
              </div>
              <div v-if="addForm.punch_in_area.length > 0" class="btn" @click="startEditorPoly()"
                style="margin-bottom: 5px">
                <span>修改</span>
              </div>
              <div v-if="adjustment" class="btn" @click="saveEditorPoly()">
                <span>保存</span>
              </div> -->
            </div>
            <div class="float-span">
              <span v-if="!addForm.punch_in_area.length > 0" class="tip">点击鼠标右键确定</span>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
      <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm" v-preventReClick>保 存</lbButton>
    </div>
  </div>
</template>

<script>
import { addRegionalRules, updateRegionalRules, getRegionalRulesDetail, getProvinceList } from '@/api/administrative'
import addressjs from '@/utils/city.js'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { getOrganizationSeleteList } from '@/api/securityApi'
import { mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      title: '',
      addForm: {
        rule_name: '',
        punch_in_area: [],
        position: '',
        longitude: '',
        latitude: '',
        // 省市区
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: '',
        organize_id: ''
      },
      addFormRules: {
        rule_name: [
          { required: true, message: '请输入规则名称', trigger: 'blur' }
        ],
        punch_in_area: [
          { type: 'array', required: true, message: '请配置打卡区域', trigger: 'change' }
        ],
        organize_id: [
          { required: true, message: '请输选择组织', trigger: 'blur' }
        ]
      },
      polyEditor: null,
      polygon: null,
      mouseTool: null,
      map: null,
      adjustment: false,
      ranges: [8, 16],
      marker: null,
      addressInfo: {
        longitude: '',
        latitude: '',
        address: '',
        province: '',
        province_code: '',
        city: '',
        city_code: '',
        district: '',
        district_code: ''
      },
      provinceSelectList: [], // 省份下拉列
      citySelectList: [], // 市下拉列
      districtSelectList: [], // 区下拉列
      autocomplete: null,
      keyword: '',
      // 组织下拉列表
      organizationSeleteList: [],
      rePaint: false // 是否是重绘
    }
  },
  created () {
    this.fnGetOrganizationSeleteList()
    if (this.id) {
      this.fnGetRegionalRulesDetail()
      this.title = '编辑规则'
    } else {
      this.title = '新增规则'
    }
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    defaultForm () {
      this.setQueryStatus(2)
      this.$emit('closeDialog', false)
    },
    initMap () {
      const that = this
      lazyAMapApiLoaderInstance.load().then(() => {
        // 1.创建地图实例
        var map = new AMap.Map('map', {
          //   center: [116.400274, 39.905812],
          zoom: 18,
          features: ['bg', 'road', 'building'] // , "point" 地标太多了显得乱
        })
        that.map = map
        this.mouseTool = new AMap.MouseTool(map)
        map.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], () => {
          var autoOptions = {
            input: 'inputkeyword'
          }
          var auto = new AMap.Autocomplete(autoOptions)
          var placeSearch = new AMap.PlaceSearch({
            map: map
          }) // 构造地点查询类
          AMap.event.addListener(auto, 'select', select) // 注册监听，当选中某条记录时会触发
          function select (e) {
            placeSearch.setCity(e.poi.adcode)
            placeSearch.search(e.poi.name) // 关键字查询查询
            // console.log("=-=-=-e=", JSON.stringify(e));
          }
        })
        // 定位
        AMap.plugin('AMap.Geolocation', function () {
          var geolocation = new AMap.Geolocation({
            enableHighAccuracy: false, // 是否使用高精度定位，默认:true
            timeout: 10000, // 超过10秒后停止定位，默认：5s
            buttonPosition: 'RB', // 定位按钮的停靠位置
            buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
            zoomToAccuracy: false, // 定位成功后是否自动调整地图视野到定位点
            showCircle: false // 是否显示定位精度圈
          })
          that.map.addControl(geolocation)
          // geolocation.getCityInfo(function (status, result) {
          //   console.log('定位中')

          //   if (status === 'complete') {
          //     console.log('定位成功', result)

          //     // onComplete(result)
          //   } else {
          //     console.log('定位失败', result)

          //     // onError(result)
          //   }
          // })
          geolocation.getCurrentPosition(function (status, result) {
            console.log('定位中...')
            if (status === 'complete') {
              console.log('定位成功')

              // onComplete(result)
            } else {
              console.log('定位失败', result)

              // onError(result)
            }
          })
        })
        // 输入提示
        that.map.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], () => {
          var autoOptions = {
            input: 'inputkeyword'
          }
          that.autocomplete = new AMap.Autocomplete(autoOptions)
          var placeSearch = new AMap.PlaceSearch({
            map: that.map
          }) // 构造地点查询类
          AMap.event.addListener(that.autocomplete, 'select', select)// 注册监听，当选中某条记录时会触发
          function select (e) {
            placeSearch.setCity(e.poi.adcode)
            placeSearch.search(e.poi.name) // 关键字查询查询
            // console.log('=-=-=-=select-==-=-=', JSON.stringify(e));
            if (e.poi.location === '') {
              that.geocoder.getLocation(`${e.poi.district}${e.poi.name}${e.poi.address}`, function (status, result) {
                if (status === 'complete' && result.geocodes.length) {
                  var lnglat = result.geocodes[0].location
                  that.sureLnglatMarker(lnglat)
                }
              })
            } else {
              that.sureLnglatMarker(e.poi.location)
            }
          }
        })
        if (this.id !== 'addnew') {
          this.nowIsEdit()
        } else {
          this.initMouseTool()
        }
      })
    },
    sureLnglatMarker (lnglat) {
      var that = this
      var specialPoint = ['北京市', '上海市', '天津市', '重庆市']
      var oneCountry_twoSystems = ['台湾省', '澳门特别行政区', '香港特别行政区']
      that.geocoder.getAddress(lnglat, function (status, result) {
        console.log('=-=-=-=getAddress=', JSON.stringify(result))
        if (status === 'complete' && result.regeocode) {
          const { province, city, district, adcode } = result.regeocode.addressComponent
          that.addForm.province = province
          that.addForm.city = city
          that.addForm.district = district
          that.addForm.province_code = addressjs.province_list.find(item => item.name === province).code
          //
          that.addressInfo.province = province
          that.addressInfo.city = city
          that.addressInfo.district = district
          that.addressInfo.province_code = addressjs.province_list.find(
            (item) => item.name === province
          ).code
          // 获取市区下拉列
          that.fngetProvinceList({
            specific_level: 2,
            specific_code: that.addressInfo.province_code
          }, 2)
          if (city === '') {
            if (specialPoint.indexOf(province) > -1) { // 直辖市
              that.addForm.city = province
              that.addressInfo.city = province
            } else if (adcode.startsWith('4690') || adcode.startsWith('6590')) { // 海南省 青海省
              that.addForm.city = district
              that.addressInfo.city = district
            } else if (oneCountry_twoSystems.indexOf(province) > -1) { // 台湾 香港 澳门
              that.addForm.province = province
              that.addForm.city = province
              that.addForm.district = province
              //
              that.addressInfo.province = province
              that.addressInfo.city = province
              that.addressInfo.district = province
            }
          }
          if (that.addForm.city === '') {
            // 有部分地区的province === "中华人民共和国"；
            return
          }
          console.log('-=========that.addForm.city=', that.addForm.city)
          that.addForm.city_code = addressjs.city_list.find(item => item.name === that.addForm.city).code
          that.addressInfo.city_code = addressjs.city_list.find(
            (item) => item.name === that.addressInfo.city
          ).code
          // 获取区级下拉列
          that.fngetProvinceList({
            specific_level: 3,
            specific_code: that.addressInfo.city_code
          }, 3)
          that.addressInfo.district_code = addressjs.district_list.find(
            (item) => item.name === that.addressInfo.district
          ).code
          that.addForm.district_code = addressjs.district_list.find(item => item.name === that.addForm.district).code
          var address = result.regeocode.formattedAddress
          that.addressInfo.address = address
          that.addressInfo.longitude = lnglat.lng
          that.addressInfo.latitude = lnglat.lat
          that.showInfoWindow(lnglat, address)
        }
      })
    },
    // 省下拉列发生改变触发
    provinceChange (val) {
      console.log('provinceChange', val)
      // 或没有数据，则清空省市区
      if (!val) {
        this.addressInfo.province = ''
        this.addressInfo.city = ''
        this.addressInfo.city_code = ''
        this.addressInfo.district = ''
        this.addressInfo.district_code = ''
        return
      };
      // 清空市区
      this.addressInfo.city = ''
      this.addressInfo.city_code = ''
      this.addressInfo.district = ''
      this.addressInfo.district_code = ''
      // 筛选出符合条件的数据
      var item = this.provinceSelectList.find(item => item.specific_code === val)
      this.addressInfo.province = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
      // 获取市下拉列表
      this.fngetProvinceList({
        specific_level: 2,
        specific_code: val
      }, 2)
    },
    // 市下拉列发生改变触发
    cityChange (val) {
      if (!val) {
        this.addressInfo.city = ''
        this.addressInfo.district = ''
        this.addressInfo.district_code = ''
        return
      }
      // 清空区级数据
      this.addressInfo.district = ''
      this.addressInfo.district_code = ''
      // 筛选出符合条件的数据
      var item = this.citySelectList.find(item => item.specific_code === val)
      this.addressInfo.city = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
      this.autocomplete.setCity(val)
      // 获取区级下拉列表
      this.fngetProvinceList({
        specific_level: 3,
        specific_code: val
      }, 3)
    },
    // 区下拉列发生改变触发
    districtChange (val) {
      // 或没有数据，则清空省市区
      if (!val) {
        this.addressInfo.district = ''
        return
      };
      // 筛选出符合条件的数据
      var item = this.districtSelectList.find(item => item.specific_code === val)
      this.addressInfo.district = item.specific_name
      // 设置地图中心点
      this.map.setCenter([item.longitude, item.latitude])
    },
    // 获取省市区列表
    async fngetProvinceList (params, type) {
      // console.log('获取省市区列表');
      // type: 1 省；2 市；3 区
      const res = await getProvinceList(params)
      console.log('fngetProvinceList', res)
      if (type === 1) {
        this.provinceSelectList = res && res.Code === 200 ? res.Data : []
      } else if (type === 2) {
        this.citySelectList = res && res.Code === 200 ? res.Data : []
      } else if (type === 3) {
        this.districtSelectList = res && res.Code === 200 ? res.Data : []
      }
    },
    // 获取组织下拉列表
    async fnGetOrganizationSeleteList () {
      const res = await getOrganizationSeleteList()
      if (res && res.Code === 200) {
        this.organizationSeleteList = res.Data
        if (this.id === 'addnew') {
          // 新增时默认选中第一项
          this.addForm.organize_id = this.organizationSeleteList[0]._id
        }
      } else {
        this.organizationSeleteList = []
      }
    },
    // 搜索
    searchMap () {
      var text = document.getElementById('inputkeyword').value
      var placeSearch = new AMap.PlaceSearch({
        map: this.map
      }) // 构造地点查询类
      placeSearch.search(text) // 关键字查询查询
    },
    // 编辑
    async nowIsEdit () {
      this.addForm._id = this.id
      const res = await getRegionalRulesDetail({ id: this.id })
      // console.log('getRegionalRulesDetail------res= ', res)
      const data = res.Data
      this.addForm = {
        _id: this.id,
        rule_name: data.rule_name,
        punch_in_area: data.punch_in_area,
        organize_id: data.organize_id
      }
      this.startEditorPoly()
    },
    // 修改
    startEditorPoly () {
      this.rePaint = false
      if (this.addForm.punch_in_area.length === 0) return
      this.adjustment = true
      this.map.clearMap()
      this.map.plugin(['AMap.Polygon', 'AMap.PolyEditor'], () => {
        var path = this.addForm.punch_in_area.map((position) => position.split(';'))
        this.polygon = new AMap.Polygon({
          path: path,
          strokeColor: '#FF33FF',
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#1791fc',
          zIndex: 50
        })
        this.map.add(this.polygon)
        // 缩放地图到合适的视野级别
        this.map.setFitView([this.polygon])
        this.polyEditor = new AMap.PolyEditor(this.map, this.polygon)
        const that = this
        this.polyEditor.on('end', function (event) {
          // console.log("polyEditor=-=-=-=", event.target.getPath());
          if (!that.rePaint) {
            that.addForm.punch_in_area = event.target
              .getPath()
              .map((position) => `${position.lng};${position.lat}`)
          }
        }
        )
        this.polyEditor.open()
      })
    },
    // 保存
    saveEditorPoly () {
      this.polyEditor && this.polyEditor.close()
      this.adjustment = false
    },
    initMouseTool () {
      const that = this
      this.mouseTool.polygon({
        strokeColor: '#FF33FF',
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillColor: '#1791fc',
        fillOpacity: 0.4,
        strokeStyle: 'solid'
      })
      this.mouseTool.on('draw', function (event) {
        var oldlnglat = event.obj
          .getPath()
          .map((position) => `${position.lng};${position.lat}`)
        // console.log("oldlnglat-=-==", oldlnglat);
        that.addForm.punch_in_area = []
        for (const item of oldlnglat) {
          if (!that.addForm.punch_in_area.includes(item)) {
            that.addForm.punch_in_area.push(item)
          }
        }
        // console.log("addForm.punch_in_area-=-==", that.addForm.punch_in_area);
        this.close(false)
      })
    },
    // 重新绘制
    resetDrawe () {
      if (this.addForm.punch_in_area.length > 0) {
      }
      this.rePaint = true
      this.addForm.punch_in_area = []
      this.adjustment = false
      this.map.clearMap()
      this.initMouseTool()
    },
    // 获取区域规则详情接口
    fnGetRegionalRulesDetail () {
      // 打开地图对话框
      this.fngetProvinceList({
        specific_level: 1
      }, 1)
      if (this.addForm.province_code) {
        // 获取市下拉列表
        this.fngetProvinceList({
          specific_level: 2,
          specific_code: this.addForm.province_code
        }, 2)
      }
      if (this.addForm.city_code) {
        // 获取区下拉列表
        this.fngetProvinceList({
          specific_level: 3,
          specific_code: this.batchAddForm.city_code
        }, 3)
      }
      this.addressInfo.province = this.addForm.province
      this.addressInfo.province_code = this.addForm.province_code
      this.addressInfo.city = this.addForm.city
      this.addressInfo.city_code = this.addForm.city_code
      this.addressInfo.district = this.addForm.district
      this.addressInfo.district_code = this.addForm.district_code
      this.initMap()
    },
    // 保存
    submitAddForm () {
      this.saveEditorPoly()
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          this.addForm.longitude = this.addressInfo.longitude
          this.addForm.latitude = this.addressInfo.latitude
          this.addForm.addres = this.addressInfo.address
          this.addForm.province = this.addressInfo.province
          this.addForm.province_code = this.addressInfo.province_code
          this.addForm.city = this.addressInfo.city
          this.addForm.city_code = this.addressInfo.city_code
          this.addForm.district = this.addressInfo.district
          this.addForm.district_code = this.addressInfo.district_code
          if (this.id === 'addnew') {
            this.fnAddRegionalRules(this.addForm)
          } else {
            this.fnUpdateRegionalRules(this.addForm)
          }
        } else {
          return false
        }
      })
    },
    // 新增区域规则接口
    async fnAddRegionalRules (obj) {
      const res = await addRegionalRules(obj)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    // 编辑区域规则接口
    async fnUpdateRegionalRules (obj) {
      const res = await updateRegionalRules(obj)
      if (res && res.Code === 200) {
        this.defaultForm()
      }
    },
    selectOrganize () {
      console.log('this.addForm.organize_id', this.addForm.organize_id)
    }
  }
}
</script>

<style scoped lang="less">
.el-dialog__wrapper {
  /deep/ .el-dialog__body {
    padding: 5px 25px;
  }

  /deep/ .el-dialog {
    margin-top: 5vh !important;
    margin: 0 auto 0px;
  }
}

.vue-calendar__container {
  /deep/ .container__year {
    height: 50px;
    font-size: 20px;

    .year__chooser[data-v-48911cf0]:nth-child(3) {
      -webkit-box-shadow: inset 0px -2px #409eff;
      box-shadow: inset 0px -2px #409eff;
    }
  }

  /deep/ .container__months {
    padding: 5px;

    .container__month {
      padding: 5px;

      .calendar {
        min-height: 208px;

        .calendar__title {
          height: 30px;
          font-size: 15px;
          margin-bottom: 0px;
        }

        .calendar__body {
          min-width: 250px;

          .calendar__day {
            height: 25px;
            font-size: 12px;
            color: #999;
          }

          .day__weektitle {
            font-size: 12px !important;
            color: #333;
          }
        }
      }

      .calendar:hover {
        z-index: 2;

        @media (min-width: 1024px) {
          transform: scale(1);
          box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  // width: 1200px;
  /deep/ .red {
    &::after {
      content: "休";
      color: #fff;
      font-size: 12px;
      width: 22px;
      height: 22px;
      background-color: red;
      line-height: 22px;
      text-align: center;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}

.map-container {
  position: relative;
  width: 100%;
  height: 500px;

  .float-select {
    position: absolute;
    top: 10px;
    left: 40%;
    translate: translateX(-50%);
    z-index: 2;
    display: flex;
    flex-direction: row;

    #inputkeyword {
      margin-right: 10px;
    }
  }
  .searchMap {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 99;
        width: 70%;
        /deep/.el-input,.el-input--small,.el-input--suffix{
        width: 150px!important;
      }
      /deep/.el-input__inner{
        width: 150px!important;
      }
      .el-select {
        width: 150px !important;
      }
  }
  .float-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    .btn {
      height: 50px;
      width: 50px;
      margin-right: 20px;
      border-radius: 25px;
      align-content: center;
      align-items: center;
      display: flex;
      justify-content: center;
      background: #0077ff;
      color: #ffffff;
    }
  }
  .float-span{
    position: absolute;
    bottom: 10px;
    right: 50%;
    transform: translateX(50%);
    z-index: 2;
    display: flex;
    flex-direction: row;
    .tip{
      height: 40px;
      width: 250px;
      margin-right: 20px;
      border-radius: 25px;
      align-content: center;
      align-items: center;
      display: flex;
      justify-content: center;
      background-color: rgba(0,0,0,0.5);
      color: #ffffff;
    }
  }
  /deep/.amap-icon{
    box-sizing: border-box;
    border: #0077ff 2px solid;
    border-radius: 50%;
  }
  /deep/.amap-icon img, .amap-marker-content img{
  width: 100% !important;
  height: 100% !important;
  width: 16px;
   height: 16px;
   transform: translateY(-60px);
   border-radius: 50%;
   filter: drop-shadow(#fff 0 60px)  //颜色、x轴偏移量、y轴偏移量,这里的颜色就是你要指定的颜色，不管原来的图片是什么颜色，都会变成这个颜色
}
}

.box-card {
  position: relative;
  padding-bottom: 30px;

  .demo-ruleForm {
    max-height: 650px;
    overflow-y: auto;
  }

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }

}</style>
